let callbacks = []

let initialized = false
let didRun = false

function initialize() {
  if (initialized) return
  initialized = true
  didRun = false
  const images = Array.from(document.querySelectorAll('img'))
  let remainingImages = images.length
  const onAllImagesLoaded = () => {
    callbacks.forEach(cb => cb())
    // reset state after run
    didRun = true
    initialized = false
    callbacks = []
  }
  const onImageLoad = () => {
    if (--remainingImages === 0 && !didRun) {
      onAllImagesLoaded()
    }
  }
  images.forEach(image => {
    image.addEventListener('load', onImageLoad)
  })
}

export default function onAllImagesLoaded(cb) {
  initialize()
  callbacks.push(cb)
}
