import React from 'react'
import { Helmet } from 'react-helmet'

const HelmetComponent = ({
  description,
  page,
  title,
  script,
  trackingCode,
}) => {
  return (
    <Helmet>
      <meta
        name="viewport"
        content="initial-scale=1.0 width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <title>{title}</title>
      <link rel="canonical" href={'https://aucta.io/' + page} />
      <meta property="og:site_name" content="Aucta" />
      <meta property="og:url" content={'https://aucta.io/' + page} />
      <meta property="og:title" content="Aucta" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        itemprop="image"
        content="https://aucta.io/og/og-921x518.png"
      />
      <meta
        property="og:image"
        itemprop="image"
        content="https://aucta.io/og/og-1200x1200.png"
      />
      <meta
        property="og:image"
        itemprop="image"
        content="https://aucta.io/og/og-400x400.png"
      />
      <meta
        property="og:image"
        itemprop="image"
        content="https://aucta.io/og/og-256x256.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://aucta.io/og/og-921x518.png" />
      <meta property="twitter:title" content="Aucta" />
      <meta name="twitter:image:alt" content="Aucta" />
      <meta property="twitter:description" content={description} />
      <meta
        name="facebook-domain-verification"
        content="zhnzgs9pcud73lxa4kgrv960cu407a"
      />
      {/* <!-- Google Tag Manager --> */}
      <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
           })(window,document,'script','dataLayer','GTM-KQ9S7ZQ');`}
      </script>
      {/* <!-- Hotjar Tracking Code for aucta.io --> */}
      <script>
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2670389,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
      {/*  Global site tag (gtag.js) - Google Ads: 494424091 */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-494424091"
      ></script>
      <script>
        {` window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'AW-494424091');
     `}
      </script>
      <script type="text/javascript">
        {`_linkedin_partner_id = "4157913"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}
      </script>
      {/* <!-- Meta Pixel Code --> */}
      <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '3270198823202184');
        fbq('track', 'PageView');`}
      </script>
      <noscript>
        {`
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=3270198823202184&ev=PageView&noscript=1"
        />
       `}
      </noscript>
      {/* <!--End Meta Pixel Code --> */}
      <noscript>
        {`
        <img
          height="1"
          width="1"
          style="display:none;"
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=4157913&fmt=gif"
        />`}
      </noscript>
      {/* <!-- Start of LiveChat (www.livechatinc.com) code --> */}
      <script>
        {`window.__lc = window.__lc || {};
        window.__lc.license = 13540410;
        ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`}
      </script>
      <noscript>{`<a href="https://www.livechatinc.com/chat-with/13540410/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>`}</noscript>
      {/* <!-- End of LiveChat code --> */}

      {/*<!-- Start cookieyes banner --> */}
      <script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/03bdef6fbad3217fd231661b/script.js"
      ></script>
      {/*<!-- End cookieyes banner -->*/}

      {/*  Specific scripts per page */}
      {trackingCode && (
        <script type="text/javascript">{trackingCode.script}</script>
      )}
      {script ? <script>{script}</script> : null}
    </Helmet>
  )
}

export default HelmetComponent
